const keys = {
	payments: 'payments',
	pairs: 'pairs',
	collaterals: 'collaterals',
	referrals: 'referrals',
	clients: 'clients',
	summaryReferrals: 'summaryReferrals',
	turnover: 'turnover',
	commission: 'commission',
	commissionChart: 'commissionChart',
	commissionCsv: 'commissionCsv',
	actions: 'actions',
	subreferralsPayments: 'subreferralsPayments',
	summarySubReferrals: 'summarySubReferrals',
	commissionSubReferrals: 'commissionSubReferrals',
	commissionSubReferralsChart: 'commissionSubReferralsChart',
	commissionCsvSubReferrals: 'commissionCsvSubReferrals',
	turnoverSubReferrals: 'turnoverSubReferrals',
	actionsSubReferrals: 'actionsSubReferrals',
	usdRates: 'usd-rates',
	affiliateBonus: 'affiliateBonus',
}

export default keys
