import { links } from 'constants/link'
import { LS_REFERRAL_PERCENT_AVALIBLE } from 'constants/variables'
import { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { ls } from 'services'
import { IFCProps } from 'types'

const RequireAuth: FC<IFCProps> = ({ children }) => {
	const location = useLocation()
	const token = ls.token
	const isAvalibleReferralPercent = ls.getItem(LS_REFERRAL_PERCENT_AVALIBLE)

	if (!token) {
		return <Navigate to={links.login} state={{ from: location }} replace />
	}

	const path = location.pathname as keyof typeof links
	const paths = Object.values(links)

	if (path === links.main || !paths.some((p) => p === path)) {
		return <Navigate to={links.dashboard} replace />
	}

	if (path === links.subAffiliateDashboard && !isAvalibleReferralPercent) {
		return <Navigate to={links.dashboard} replace />
	}

	return children
}

export default RequireAuth
