import { LS_REFERRAL_PERCENT_AVALIBLE, MIN_REFERRAL_PERCENT } from 'constants/variables'
import { ls } from 'services'
import useSummaryReferralsQuery from '../query/useSummaryReferralsQuery'

const useSummaryReferralPercentIsAvalible = () =>
	useSummaryReferralsQuery({
		select: (summary) => Boolean(summary.subReferralPercent > MIN_REFERRAL_PERCENT),
		onSuccess: (isPercentAvalible: boolean) => {
			ls.setItem(LS_REFERRAL_PERCENT_AVALIBLE, isPercentAvalible)
			return isPercentAvalible
		},
	})

export default useSummaryReferralPercentIsAvalible
