import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

const showErrorToast = (e: unknown) => {
	const error = e as AxiosError<{ message: string }>
	let message = error.message

	if (error.response?.data?.message) {
		message = error.response?.data?.message
	}

	toast(message)
}

export default showErrorToast
