import { links } from 'constants/link'
import { lazy } from 'react'

const Dashboard = lazy(() => import('features/Dashboard'))
const Clients = lazy(() => import('features/Clients'))
const Commissions = lazy(() => import('features/Commissions'))
const SubAffiliateDashboard = lazy(() => import('features/SubAffiliateDashboard'))
const Login = lazy(() => import('features/Login'))

const { dashboard, clients, commissions, subAffiliateDashboard, login } = links

const privateRoutes = [
	{ path: dashboard, LazyElement: Dashboard },
	{ path: clients, LazyElement: Clients },
	{
		path: commissions,
		LazyElement: Commissions,
	},
	{
		path: subAffiliateDashboard,
		LazyElement: SubAffiliateDashboard,
	},
	{ path: '/*', LazyElement: Dashboard },
]

const publickRoutes = [
	{
		path: login,
		LazyElement: Login,
	},
]

export default { privateRoutes, publickRoutes }
