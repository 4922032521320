import { fetchSummaryRefferals } from 'api'
import keys from 'constants/queryKeys'
import { useQuery } from 'react-query'
import { ISummaryReferralQuery } from 'types'

const empty = () => null

const useSummaryReferralsQuery = <T>({ select, onSuccess }: ISummaryReferralQuery<T>) => {
	return useQuery(keys.summaryReferrals, fetchSummaryRefferals, {
		select,
		onSuccess: onSuccess || empty,
	})
}

export default useSummaryReferralsQuery
