class LocalStorageService {
	private lsTokenKey = 'AFFILIATE_TOKEN'

	get token() {
		const token = window.localStorage.getItem(this.lsTokenKey)
		if (!token) {
			return token
		}
		return JSON.parse(token) as string
	}

	get tokenKey() {
		return this.lsTokenKey
	}

	setToken(token: string) {
		window.localStorage.setItem(this.lsTokenKey, JSON.stringify(token))
	}

	clear() {
		window.localStorage.clear()
	}

	setItem(key: string, value: any) {
		window.localStorage.setItem(key, JSON.stringify(value))
	}
	getItem(key: string) {
		const item = window.localStorage.getItem(key)
		if (!item) {
			return item
		}
		return JSON.parse(item)
	}
}

export default new LocalStorageService()
