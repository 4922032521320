export const BASE_URL = process.env['REACT_APP_BASE_URL'] || ''
export const AFFILATE_URL = process.env['REACT_APP_AFFILATE_URL'] || ''
export const REACT_APP_MARGEX_URL = process.env['REACT_APP_MARGEX_URL'] || ''

export const BASE_REFERRAL_URL = `${BASE_URL}?rid=`
export const REFERRAL_WHATSAPP = `${process.env['REACT_APP_REFERRAL_WHATSAPP'] || ''}${REACT_APP_MARGEX_URL}?rid=`
export const REFERRAL_FACEBOOK = `${process.env['REACT_APP_REFERRAL_FACEBOOK'] || ''}${REACT_APP_MARGEX_URL}?rid=`
export const REFERRAL_TELEGRAM = `${process.env['REACT_APP_REFERRAL_TELEGRAM'] || ''}${REACT_APP_MARGEX_URL}?rid=`
export const REFERRAL_TWITTER = `${process.env['REACT_APP_REFERRAL_TWITTER'] || ''}${REACT_APP_MARGEX_URL}?rid=`
export const MARKETING_KIT = process.env['REACT_APP_MARKETING_KIT'] || ''
