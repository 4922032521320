import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { AFFILATE_URL, BASE_URL } from 'constants/env'
import { links } from 'constants/link'
import { ls } from 'services'

class HttpService {
	private axios: AxiosInstance

	constructor() {
		this.axios = this.createInstance()

		this.setInterceptors()
	}

	private createInstance() {
		return axios.create({ baseURL: AFFILATE_URL, timeout: 60000 })
	}

	private onRequestFullfiled(config: AxiosRequestConfig<any>) {
		const token = config.data?.token || ls.token

		config.headers = {
			...config.headers,
			Authorization: token,
		}

		if (config.data?.base) {
			config.baseURL = BASE_URL
		}

		return config
	}

	private onResponseError(error: AxiosError<any>) {
		if (error?.response?.status === 401 && window.location.pathname !== links.login) {
			ls.clear()
			window.location.replace(links.login)
		}

		return Promise.reject(error)
	}

	private setInterceptors() {
		this.axios.interceptors.request.use(this.onRequestFullfiled)
		this.axios.interceptors.response.use((response) => response, this.onResponseError)
	}

	get<T>(path: string, config?: AxiosRequestConfig<object>) {
		return this.axios.get<object, T>(path, config)
	}
	post<T>(path: string, data?: object, config?: AxiosRequestConfig<object>) {
		return this.axios.post<object, T>(path, data, config)
	}
}

export default new HttpService()
