import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import { FC } from 'react'
import { IFCProps } from 'types'

const theme = createTheme()

const MaterialThemeProvider: FC<IFCProps> = ({ children }) => {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	)
}

export default MaterialThemeProvider
