import { AxiosResponse } from 'axios'
import queryString from 'query-string'
import { http } from 'services'
import { TPaymentsAndReferralsFilters } from '../features/Dashboard/components/types'
import { FetchSubReferralsCommissionPayload, IFetchCommissionCHARTPayload, SubReferralCommissionChart } from '../types'
import {
	AffiliateBonusResponse,
	Collaterals,
	Commissions,
	IActions,
	IClientsData,
	ICommission,
	ICommissionsChart,
	IFetchClientsPayload,
	IFetchCommissionPayload,
	IFetchFromToPayload,
	IFetchLoginPayload,
	IFetchSubReferralsActionsPayload,
	IFetchSubReferralsCommissionPayload,
	IFetchSubreferralsTurnoverPayload,
	IPairs,
	IPayments,
	IPaymentsAndReferrals,
	ISubReferralsCommission,
	ISummaryReferrals,
	ISummarySubReferrals,
	QueryPayload,
	ResponseData,
	SubReferralsCommissions,
	Turnovers,
} from './types'

const REFERRAL_URL = 'api/v1/referrals'
const PAIRS_URL = '/client-trade/api/v1/'
const COLLATERALS_URL = '/client-payment/api/v1/collateral'
const SUB_REFERRAL_URL = 'api/v1/subreferrals'
const AFFILIATE_URL = 'api/v1/affiliate/'

export const login = async ({ token, ...query }: IFetchLoginPayload) => {
	const stringifyQuery = queryString.stringify(query)
	return http.get(`${REFERRAL_URL}${stringifyQuery}`, { data: { token } })
}

export const fetchPairs = async () => {
	const { data } = await http.get<AxiosResponse<IPairs>>(`${PAIRS_URL}settings/pairsList`, { data: { base: true } })
	return data
}

export const fetchCollaterals = async () => {
	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<Collaterals>>>(COLLATERALS_URL, { data: { base: true } })
	return data
}

export const fetchAffiliateBonus = async () => {
	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<AffiliateBonusResponse>>>(`${AFFILIATE_URL}bonus`)
	return data
}

export const fetchPaymentsAndReferrals = async (filters: QueryPayload<TPaymentsAndReferralsFilters>) => {
	const query = filters.queryKey[1] as TPaymentsAndReferralsFilters | undefined
	const qs = queryString.stringify(query || {})
	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<IPaymentsAndReferrals>>>(`${REFERRAL_URL}?${qs}`)

	return data
}

export const fetchClients = async (payload: QueryPayload<any | undefined>) => {
	const query = payload.queryKey[1] as IFetchClientsPayload | undefined
	const stQuery = queryString.stringify(query || {})

	const {
		data: { data },
	} = await http.get<AxiosResponse<IClientsData>>(`${REFERRAL_URL}/clients?${stQuery}`)

	return data
}

export const fetchSummaryRefferals = async () => {
	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<ISummaryReferrals>>>(`${REFERRAL_URL}/summary`)

	return data
}

export const fetchTurnover = async (payload: QueryPayload<IFetchFromToPayload & { dataType: 'trading' | 'copytrading' }>) => {
	const { dataType, ...query } = payload.queryKey[1] as IFetchFromToPayload & { dataType: 'trading' | 'copytrading' }

	const stQuery = queryString.stringify({ ...query, ct: dataType === 'copytrading' })

	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<Turnovers>>>(`${REFERRAL_URL}/clients/turnover?${stQuery}`)

	return data
}

export const fetchCommissions = async (payload: QueryPayload<IFetchCommissionPayload | undefined>) => {
	const query = payload.queryKey[1] as IFetchCommissionPayload | undefined
	const stQuery = queryString.stringify(query || {})

	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<Commissions>>>(`${REFERRAL_URL}/commissions?${stQuery}`)

	return data
}

export const fetchCommissionsChart = async (payload: QueryPayload<IFetchCommissionCHARTPayload | undefined>) => {
	const query = payload.queryKey[1] as IFetchCommissionCHARTPayload | undefined
	const stQuery = queryString.stringify(query || {})

	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<ICommissionsChart>>>(`${REFERRAL_URL}/commissions/chart?${stQuery}`)

	return data
}

export const fetchCommissionsCsv = async (payload: QueryPayload<ICommission[] | undefined>) => {
	const body = (payload.queryKey[1] as string) || ''

	const { data } = await http.post<AxiosResponse<string>>(`${REFERRAL_URL}/commissions/csv`, JSON.parse(body))

	return data
}

export const fetchActions = async (payload: QueryPayload<IFetchFromToPayload | undefined>) => {
	const query = payload.queryKey[1] as IFetchFromToPayload | undefined
	const stQuery = queryString.stringify(query || {})

	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<IActions>>>(`${REFERRAL_URL}/actions?${stQuery}`)

	return data
}

export const fetchSubreferralsPayments = async () => {
	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<IPayments>>>(`${SUB_REFERRAL_URL}/payments`)
	return data
}

export const fetchSummarySubRefferals = async () => {
	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<ISummarySubReferrals>>>(`${SUB_REFERRAL_URL}/summary`)

	return data
}

export const fetchSubReferralsCommissions = async (payload: QueryPayload<IFetchSubReferralsCommissionPayload | undefined>) => {
	const query = payload.queryKey[1] as IFetchSubReferralsCommissionPayload | undefined
	const stQuery = queryString.stringify(query || {})

	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<SubReferralsCommissions>>>(`${SUB_REFERRAL_URL}/commissions?${stQuery}`)

	return data
}

export const fetchSubReferralsCommissionsCsv = async (payload: QueryPayload<ISubReferralsCommission[] | undefined>) => {
	const body = payload.queryKey[1] as ISubReferralsCommission[]

	const { data } = await http.post<AxiosResponse<string>>(`${SUB_REFERRAL_URL}/commissions/csv`, body)

	return data
}

export const fetchSubReferralsTurnover = async (payload: QueryPayload<IFetchSubreferralsTurnoverPayload>) => {
	const query = payload.queryKey[1] as IFetchSubreferralsTurnoverPayload

	const stQuery = queryString.stringify(query)

	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<Turnovers>>>(`${SUB_REFERRAL_URL}/turnover?${stQuery}`)

	return data
}

export const fetchSubReferralsActions = async (payload: QueryPayload<IFetchSubReferralsActionsPayload | undefined>) => {
	const query = payload.queryKey[1] as IFetchSubReferralsActionsPayload | undefined
	const stQuery = queryString.stringify(query || {})

	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<IActions>>>(`${SUB_REFERRAL_URL}/actions?${stQuery}`)

	return data
}

export const fetchSubReferralsCommissionsChart = async (payload: QueryPayload<FetchSubReferralsCommissionPayload | undefined>) => {
	const query = payload.queryKey[1] as FetchSubReferralsCommissionPayload | undefined
	const stQuery = queryString.stringify(query || {})

	const {
		data: { data },
	} = await http.get<AxiosResponse<ResponseData<SubReferralCommissionChart>>>(`${SUB_REFERRAL_URL}/commissions/chart?${stQuery}`)

	return data
}
