import { StrictMode } from 'react'
import Router from './router/Router'
import { ReactQueryProvider } from 'providers/ReactQueryProvider'
import MaterialThemeProvider from 'providers/MaterialThemeProvider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const App = () => (
	<StrictMode>
		<ReactQueryProvider>
			<MaterialThemeProvider>
				<>
					<Router />
					<ToastContainer />
				</>
			</MaterialThemeProvider>
		</ReactQueryProvider>
	</StrictMode>
)
export default App
