import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { links } from 'constants/link'
import { ls } from 'services'
import { useQueryClient } from 'react-query'

const useLogout = () => {
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const logout = useCallback(() => {
		ls.clear()
		queryClient.clear()
		navigate(links.login, { replace: true })
	}, [])

	return logout
}

export default useLogout
