import { FC } from 'react'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { IFCProps } from 'types'
import { ReactQueryDevtools } from 'react-query/devtools'
import showErrorToast from 'utils/showErrorToast'

export const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError(e) {
			showErrorToast(e)
		},
	}),
	defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false }, mutations: { retry: false } },
})

export const ReactQueryProvider: FC<IFCProps> = ({ children }) => {
	return (
		<QueryClientProvider client={queryClient}>
			{children}
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	)
}
