import { links } from 'constants/link'
import DefaultLayout from 'layouts/DefaultLayout'
import SuspenseProvider from 'providers/SuspenseProvider'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import RequireAuth from './RequireAuth'
import routes from './routes'

const { privateRoutes, publickRoutes } = routes

const Router = () => (
	<BrowserRouter>
		<Routes>
			<Route
				element={
					<RequireAuth>
						<DefaultLayout />
					</RequireAuth>
				}
				path={links.main}
			>
				{privateRoutes.map(({ path, LazyElement }) => {
					return (
						<Route
							key={path}
							path={path}
							element={
								<SuspenseProvider>
									<LazyElement />
								</SuspenseProvider>
							}
						/>
					)
				})}
			</Route>
			{publickRoutes.map(({ path, LazyElement }) => {
				return (
					<Route
						key={path}
						path={path}
						element={
							<SuspenseProvider>
								<LazyElement />
							</SuspenseProvider>
						}
					/>
				)
			})}
		</Routes>
	</BrowserRouter>
)

export default Router
