import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import LayersIcon from '@mui/icons-material/Layers'

export const links = {
	main: '/',
	dashboard: '/dashboard',
	clients: '/clients',
	commissions: '/commissions',
	subAffiliateDashboard: '/sub-affiliate-dashboard',
	login: '/login',
}

export const authPageIcons = {
	[links.dashboard]: DashboardIcon,
	[links.clients]: PeopleAltIcon,
	[links.commissions]: ShoppingCartIcon,
	[links.subAffiliateDashboard]: LayersIcon,
}

export const authPageNames = {
	[links.dashboard]: 'Margex Affiliate Dashboard',
	[links.clients]: 'Clients',
	[links.commissions]: 'Commissions',
	[links.subAffiliateDashboard]: 'Sub-affiliate Dashboard',
}

export const authPageNamesWithLink = [
	{
		name: authPageNames[links.dashboard],
		link: links.dashboard,
		Icon: authPageIcons[links.dashboard],
	},
	{
		name: authPageNames[links.clients],
		link: links.clients,
		Icon: authPageIcons[links.clients],
	},
	{
		name: authPageNames[links.commissions],
		link: links.commissions,
		Icon: authPageIcons[links.commissions],
	},
	{
		name: authPageNames[links.subAffiliateDashboard],
		link: links.subAffiliateDashboard,
		Icon: authPageIcons[links.subAffiliateDashboard],
	},
]

export const routeType = 'auth' as const
