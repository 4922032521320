import { AppBar as MuiAppBar, IconButton, Toolbar, Typography, AppBarProps as MuiAppBarProps, styled, Box } from '@mui/material'
import { FC, useMemo } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import drawerWidth from 'constants/drawer'
import { useLocation } from 'react-router-dom'
import { authPageNames } from 'constants/link'
import { DrawerProps } from 'layouts/DefaultLayout/types'
import LogoutIcon from '@mui/icons-material/Logout'
import useLogout from 'hooks/useLogout'

const AppBar: FC<DrawerProps> = ({ open, handleDrawerOpen }) => {
	const location = useLocation()
	const logout = useLogout()
	const name = useMemo(() => authPageNames[location.pathname] || '', [location])

	return (
		<AppBarContainer open={open}>
			<FlexToolbar>
				<MenuToolbarContainer>
					<StyledIconButton open={open} onClick={handleDrawerOpen} edge='start'>
						<MenuIcon />
					</StyledIconButton>
					<Typography variant='h6' noWrap component='div' display={'flex'} alignItems={'center'} gap={'8px'}>
						{name} <Badge>beta 2.3</Badge>
					</Typography>
				</MenuToolbarContainer>
				<StyledIconButton open={false} onClick={logout}>
					<LogoutIcon />
				</StyledIconButton>
			</FlexToolbar>
		</AppBarContainer>
	)
}

export default AppBar

const AppBarContainer = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	position: 'fixed',
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}))

const StyledIconButton = styled(IconButton)<{ open: boolean }>(({ open }) => ({
	color: 'inherit',
	marginRight: 40,
	...(open && { display: 'none' }),
}))

const Badge = styled('span')`
	background: linear-gradient(180deg, rgba(48, 170, 238, 0.9) 0%, rgba(48, 170, 238, 0.54) 100%);
	padding: 2px 0.5rem;
	color: #f7fcff;
	border-radius: 24px;
	font-weight: 600;
	font-size: 0.625rem;
	line-height: 21px;
	text-transform: uppercase;
`

const MenuToolbarContainer = styled(Box)({
	display: 'flex',
	alignItems: 'center',
})

const FlexToolbar = styled(Toolbar)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
})

interface AppBarProps extends MuiAppBarProps {
	open?: boolean
}
