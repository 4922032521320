import { styled, Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DrawerHeader from 'layouts/DefaultLayout/styles'
import { ListItemButton } from '@mui/material'
import drawerWidth from 'constants/drawer'
import { authPageNamesWithLink, links } from 'constants/link'
import { FC } from 'react'
import { DrawerProps } from 'layouts/DefaultLayout/types'
import DisabledLink from 'components/DisabledLink'
import { useLocation } from 'react-router-dom'

const MiniDrawer: FC<DrawerProps> = ({ open, handleDrawerClose, disableSubAffiliateDashboard }) => {
	const location = useLocation()

	return (
		<>
			<Drawer variant='permanent' open={open}>
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon />
					</IconButton>
				</DrawerHeader>
				<Divider />
				<List>
					{authPageNamesWithLink.map(({ name, link, Icon }) => {
						const disabled = link === links.subAffiliateDashboard && Boolean(disableSubAffiliateDashboard)
						const isActive = link === location.pathname
						const IconColor = isActive ? 'primary' : 'inherit'

						if (disabled) {
							return null
						}

						return (
							<ListItemBlock key={link} disablePadding>
								<StyledLink to={link} disabled={disabled}>
									<StyledListItemButton disabled={disabled}>
										<StyledListItemIcon>
											<Icon color={IconColor} />
										</StyledListItemIcon>
										<StyledListItemText primary={name} $isActive={isActive} />
									</StyledListItemButton>
								</StyledLink>
							</ListItemBlock>
						)
					})}
				</List>
			</Drawer>
			<DrawerHeader />
		</>
	)
}
export default MiniDrawer

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(6)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(7)} + 1px)`,
	},
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}))

const StyledListItemButton = styled(ListItemButton)`
	min-height: 48px;
	padding: 8px 16px;
`

const StyledListItemIcon = styled(ListItemIcon)`
	min-width: 40px;
`

const ListItemBlock = styled(ListItem)({
	display: 'block',
})

const StyledListItemText = styled(ListItemText, { shouldForwardProp: (prop) => prop !== '$isActive' })<{ $isActive: boolean }>(({ theme, $isActive }) => ({
	color: $isActive ? theme.palette.primary.main : theme.palette.common.black,
}))

const StyledLink = styled(DisabledLink)({
	color: 'rgba(0,0,0,0.87)',
	textDecoration: 'none',
})
