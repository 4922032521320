import { Box, CircularProgress } from '@mui/material'
import { FC, Suspense } from 'react'
import { IFCProps } from 'types'

const Fallback = () => (
	<Box sx={{ height: '80vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
		<CircularProgress />
	</Box>
)

const SuspenseProvider: FC<IFCProps> = ({ children }) => {
	return <Suspense fallback={<Fallback />}>{children}</Suspense>
}

export default SuspenseProvider
