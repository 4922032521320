import { FC } from 'react'
import { Link } from 'react-router-dom'
import { IDisabledLinkProps } from 'types'

const DisabledLink: FC<IDisabledLinkProps> = ({ disabled, children, ...props }) => {
	if (disabled) {
		return <>{children}</>
	}
	return <Link {...props}>{children}</Link>
}

export default DisabledLink
