import { IActions } from 'api/types'
import dayjs from 'dayjs'
import { IDealsBonusValues } from 'types'
import { DEFAULT_FORMAT } from './dayjs'

export const DATE_OPTIONS = [
	{
		name: 'Last 7 days',
		value: 7,
	},
	{
		name: 'Last month',
		value: 30,
	},
	{
		name: 'Last 90 days',
		value: 90,
	},
]

export const DATE_RANGE_DEFAULT_VALUE = {
	startDate: undefined,
	endDate: undefined,
	key: 'range1',
}

export const MIN_REFERRAL_PERCENT = 0

export const LS_REFERRAL_PERCENT_AVALIBLE = 'affiliate_referral_percent_avalible'

export const DATE_SELECTOR_DEFAULT_VALUES = {
	from: dayjs().subtract(DATE_OPTIONS[2].value, 'days').format(DEFAULT_FORMAT),
	to: dayjs().format(DEFAULT_FORMAT),
}

export const RESENT_SELECT_OPTIONS = [
	{ value: 'signups', name: 'Signup' },
	{ value: 'totalReferralsFundedCount', name: 'All Deposits' },
	{ value: 'activeTraders', name: 'Active trader' },
	{ value: 'firstTimeDeposits', name: 'FTD' },
	// { value: 'uniqueCtUsers', name: 'Unique CT clients' },
] as Array<{ value: keyof IActions; name: string }>

export const BONUS_DEALS_OPTIONS = [
	{ value: 'commissionsPaidWithoutBonuses', name: 'Commissions paid without bonuses' },
	{ value: 'commissionsCoveredByBonuses', name: 'Commissions covered by bonuses' },
] as Array<{ value: IDealsBonusValues; name: string }>

export const PAYOUT_STATUS_OPTIONS = [
	{ value: 0, name: 'Pending' },
	{ value: 1, name: 'Paid' },
]

export const DEFAULT_POPOVER_SETTINGS = {
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'left',
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'left',
	},
	id: 'mouse-over-popover',
	sx: {
		pointerEvents: 'none',
	},
} as const

export const DEFAULT_POPOVER_SETTINGS_TOP = {
	...DEFAULT_POPOVER_SETTINGS,
	anchorOrigin: {
		vertical: 'top',
		horizontal: 'left',
	},
	id: 'mouse-over-popover',
	sx: {
		pointerEvents: 'none',
	},
} as const

export const SATOSHI_DEVIDER = 100000000
export const SECOND_IN_MS = 1000
export const MINUTE = 60 * SECOND_IN_MS
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR

export const CURRENCIES = {
	ALL_USDC: 'ALL_USDC',
	ALL_USDT: 'ALL_USDT',
	ALL_BUSD: 'ALL_BUSD',
	ALL_ETH: 'ALL_ETH',
	ALL_MATIC: 'ALL_MATIC',
	ALL_TUSD: 'ALL_TUSD',
	ALL_OPT: 'ALL_OPT',

	BTC: 'BTC',
	ETH: 'ETH',
	USDC: 'USDC',
	WBTC: 'WBTC',
	USDT: 'USDT',
	DAI: 'DAI',
	LINK: 'LINK',
	PAX: 'PAX',
	DEMO: 'DEMO',
	T_USDT: 'T_USDT',
	SHIB: 'SHIB',
	TRX: 'TRX',
	UST: 'UST',
	MATIC: 'MATIC',
	P_USDT: 'P_USDT',
	P_USDC: 'P_USDC',
	SOL: 'SOL',
	S_USDT: 'S_USDT',
	S_USDC: 'S_USDC',
	MATIC_ERC: 'MATIC_ERC',
	MRX: 'MRX',
	LTC: 'LTC',
	AVAX: 'AVAX',
	A_USDT: 'A_USDT',
	A_USDC: 'A_USDC',
	T_USDC: 'T_USDC',
	BNB: 'BNB',
	B_USDT: 'B_USDT',
	B_USDC: 'B_USDC',
	BCH: 'BCH',
	B_BUSD: 'B_BUSD',
	E_BUSD: 'E_BUSD',
	P_BUSD: 'P_BUSD',
	A_BUSD: 'A_BUSD',
	XRP: 'XRP',
	TON: 'TON',
	STMATIC: 'STMATIC',
	STETH: 'STETH',
	T_TUSD: 'T_TUSD',
	T_USDD: 'T_USDD',
	ARB_ARB: 'ARB_ARB',
	ARB_ETH: 'ARB_ETH',
	OPT_OPT: 'OPT_OPT',
	TON_USDT: 'TON_USDT',
	KAS: 'KAS',
	S_BOM: 'S_BOM',

	ALL_POL: 'ALL_POL',
	B_POL: 'B_POL',
	STPOL: 'STPOL',
	POL_ERC: 'POL_ERC',
	POL: 'POL',
	DOGE: 'DOGE',
	P_USDCE: 'P_USDCE',
	ALL_RLUSD: 'ALL_RLUSD',
	E_RLUSD: 'E_RLUSD',
	XRP_RLUSD: 'XRP_RLUSD',
	BS_ETH: 'BS_ETH',
	BS_CBBTC: 'BS_CBBTC',
	BS_WETH: 'BS_WETH',
	BS_USDT: 'BS_USDT',
	BS_USDC: 'BS_USDC',
	ALL_CBBTC: 'ALL_CBBTC',
	ALL_WETH: 'ALL_WETH',
	S_MEM_TRUMP: 'S_MEM_TRUMP',
	S_MEM_MELANIA: 'S_MEM_MELANIA',
}

export const USD_RATES_MAPPING = {
	[CURRENCIES.BTC]: 1,
	[CURRENCIES.WBTC]: 1,
	[CURRENCIES.ETH]: 2,
	[CURRENCIES.ARB_ETH]: 2,
	[CURRENCIES.MATIC]: 3,
	[CURRENCIES.MATIC_ERC]: 3,
	[CURRENCIES.TRX]: 4,
	[CURRENCIES.LTC]: 5,
	[CURRENCIES.SOL]: 6,
	[CURRENCIES.LINK]: 7,
	[CURRENCIES.AVAX]: 8,
	[CURRENCIES.BNB]: 9,
	[CURRENCIES.XRP]: 10,

	[CURRENCIES.POL]: 3,
	[CURRENCIES.POL_ERC]: 3,
}
