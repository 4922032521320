import { Box, CssBaseline, styled } from '@mui/material'
import useSummaryReferralPercentIsAvalible from 'hooks/selectors/useSummaryReferralPercentIsAvalible'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import AppBar from './components/AppBar'
import Drawer from './components/Drawer'
import DrawerHeader from './styles'

const DefaultLayout = () => {
	const [open, setOpen] = useState(false)
	const { data } = useSummaryReferralPercentIsAvalible()

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	return (
		<Container>
			<CssBaseline />
			<AppBar open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
			<Drawer disableSubAffiliateDashboard={!data} open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
			<Main component='main'>
				<DrawerHeader />
				<Outlet />
			</Main>
		</Container>
	)
}

export default DefaultLayout

const Container = styled(Box)(({ theme }) => ({
	width: '100%',
	minHeight: '100vh',
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: theme.palette.grey[100],
}))

const Main = styled(Box)(({ theme }) => ({
	flexGrow: 1,
	width: '100%',
	padding: 20,
	maxWidth: 1900,
	backgroundColor: theme.palette.grey[100],
	overflow: 'hidden',
}))
